import { React, useState, useEffect } from 'react'
import * as Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Helmet } from "react-helmet-async";
import axios from "axios";
import config from "../../config/index";
import { useNavigate } from 'react-router-dom';
//Help: /payment/checkout?amount=2000&unit=usd&servicefor=1%20image%20enhancement
const Checkout = ({ loginUser, registerUser, getStyleClass, t, i18n, isLoggedin, setIsLoggedin }) => {
    const [regHid, setRegHid] = useState(false);
    const [amnt, setAmnt] = useState("0");
    const [unt, setUnit] = useState("$");
    const [serviceFor, setServiceFor] = useState(t("Increase_Credit"));

    const [errTxt, setErrTxt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(t("Are_you_sure_you"));
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [title, setTitle] = useState(t("Confirm"));

    const navigate = useNavigate()
    useEffect(() => {
        if (isLoggedin) {
            setRegHid(true);
        }

        const urlParams = new URLSearchParams(window.location.search);
        const myParamAmnt = urlParams.get('amount');
        const myParamUnit = urlParams.get('unit');
        const myParamService = urlParams.get('servicefor');

        setUnit(myParamUnit)
        setAmnt(myParamAmnt)
        if (myParamService != null && myParamService != "")
            setServiceFor(myParamService)
    })
    const signUp = async () => {
        if (document.getElementById("fullname_signup").value == "null"
            || document.getElementById("email_signup").value == "null"
            || document.getElementById("pass_signup").value == "null"
            || document.getElementById("repass_signup").value == "null"
            || document.getElementById("pass_signup").value != document.getElementById("repass_signup").value) {

            return;
        }
        const data = {
            name: document.getElementById("fullname_signup").value,
            email: document.getElementById("email_signup").value,
            password: document.getElementById("pass_signup").value,
            errors: "",
            lang: i18n.language,
        };
        var res = await registerUser(data);

        if (res.status) {
            setMessage(t("Activation_Email_has_been"));
            setTitle(t("Dear_User"));
            handleShow();
            setErrTxt(t("Activation_Email_has_been_pay_again"))
        }
        else {
            if (res.Message == "This email is already registered") {
                setMessage(t("You_are_already_registered"));
                setTitle(t("Dear_User"));
                handleShow();
                setErrTxt(t("You_are_already_registered"))

            }
        }
    }
    async function payment() {
        const urlParams = new URLSearchParams(window.location.search);
        const myParamAmnt = urlParams.get('amount');
        const myParamOrderId = urlParams.get('orderId');
        const myParamOrderDesc = urlParams.get('orderDesc');
        const token = JSON.parse(localStorage.getItem("mosavverData")).accessToken;
        const usrId = JSON.parse(localStorage.getItem("mosavverData")).user.id;
        const response = await axios.post(`${config.apiUrlOn}/credit/api/transaction/create`,
            {
                amount: myParamAmnt,
                orderId: myParamOrderId,
                orderDesc: myParamOrderDesc,
                userid: usrId
            }, { headers: { Authorization: `Bearer ${token}` } });

        return {
            payAddress: response.data.data.payAddress,
            expDate: response.data.expDate,
            pay_amount: response.data.data.pay_amount.toString()
        }
    }
    // function sleep(ms) {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    //  }
    async function pay() {
        var loginData = localStorage.getItem("mosavverData");
        if (!isLoggedin && loginData == null) {
            setIsLoading(true);
            await signUp();
            setIsLoading(false);
        }
        else {
            setIsLoading(true);
            const res = await payment();
            setIsLoading(false);

            // location.href = "/payment/payCrypto?amount=" + payAmnt + "&orderId=" + "test&orderDesc=" + "firstPay&payAddress=" + payAddress
            // + "&expirationDate=" + expirationDate;
            if (i18n.language === "en")
                navigate("/payment/payCrypto?amount=" + res.pay_amount + "&orderId=" + "test&orderDesc=" + "firstPay&payAddress=" + res.payAddress
                    + "&expirationDate=" + res.expDate)
            else
                navigate("/" + i18n.language + "/payment/payCrypto?amount=" + res.pay_amount + "&orderId=" + "test&orderDesc=" + "firstPay&payAddress=" + res.payAddress
                + "&expirationDate=" + res.expDate)
        }

    }

    function confirmed() {
        handleClose();
    }
    return (
        <>
            <Helmet>
                <title>{t("Payment_Checkout")}</title>
            </Helmet>
            <div className={"container " + getStyleClass()}>
                {isLoading ? <div style={{ fontFamily: "BYekan", color: "white", marginRight: "50%", marginLeft: "50%", marginTop: "10%", zIndex: "24", textAlign: "center", position: "absolute" }}><Loader.TailSpin /> {t("loading")}</div> : <></>}

                <div className="py-5 text-center">

                    <h2>{t("Checkout_form")}</h2>

                </div>

                <div className="row">

                    <div className="col-md-8 order-md-1">
                        {/* <form className="needs-validation" novalidate> */}
                        <div hidden={regHid}>
                            <p className="lead">{t("If_you_are_already")}</p>

                            <h4 className="mb-3">{t("Register")}</h4>
                            <div className="mb-3">
                                <input type="text" className="form-control" id="fullname_signup" placeholder={t("Full_Name")} />
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control" id="email_signup" placeholder={t("Email")} />
                                <div className="invalid-feedback">
                                    {t("Please_enter_a_valid")}
                                </div>
                            </div>

                            <div className="mb-3">
                                <input type="password" className="form-control" id="pass_signup" placeholder={t("Password")} required />
                                <div className="invalid-feedback">
                                    {t("Please_enter_your_password.")}
                                </div>
                            </div>
                            <div className="mb-3">
                                <input type="password" className="form-control" id="repass_signup" placeholder={t("Repeat_Password")} required />
                                <div className="invalid-feedback">
                                    {t("Please_Re_enter_your")}
                                </div>
                            </div>
                            <div hidden={errTxt === "" ? true : false}>
                                <hr className="mb-4" />

                                <div class="alert alert-danger" role="alert">
                                    {errTxt}
                                </div>
                            </div>
                            <hr className="mb-4" />
                        </div>


                        <h4 className="mb-3">{t("Payment")}</h4>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label for="PaymentAmount">{t("Amount")}</label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <span>{amnt} {unt}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label for="PaymentAmount">{t("Service")}</label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <span>{serviceFor}</span>
                            </div>
                        </div>
                        <hr className="mb-4" />
                        <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={pay}>{t("Continue_to_checkout") + " " + amnt + " " + unt}</button>
                    </div>
                </div>
                <Modal className={getStyleClass()}
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header >
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            {message}
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="primary" onClick={confirmed} >{t("OK")}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )

}

export default Checkout