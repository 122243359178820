import axios from "axios";
import config from "../config/index";

export default class AuthService {
	async registerUser(data) {
		try {
			const response = await axios.post(`${config.apiUrlOn}/auth/api/auth/signup`, {
				name: data.name,
				email: data.email,
				password: data.password,
				destination: data.lang === "en" ? "https://mosavver.com/Auth/Login" : "https://mosavver.com/" + data.lang + "/Auth/Login",
			});
			//console.log(response.data)
			//return response.data;
			if(response.status == 200){
				return {
					status: true,
					Message: "Success Register",
					data: response.data
				}
			}
			else{
				return {
					status: false,
					Message: response.statusText,
					data: null
				}
			}
		} catch (errors) {
			console.log(errors)
			if(errors.response != null && errors.response.data.code == 401){
				var res = {
					status: false,
					Message: "incorrect Username or Password",
					data: null
				};
				//console.log(res)

				return res;
			}
			else{
				return {
					status: false,
					Message: errors.response.data.message,
					data: null
				}
			}
		}
	}

	async loginUser(data) {

		try {
			const response = await axios.post(`${config.apiUrlOn}/auth/api/auth/signin`, {
				email: data.email,
				password: data.password
			});

			console.log(response)
			if(response.status == 200){
				return {
					status: true,
					Message: "Success Login",
					data: response.data
				}
			}
			else{
				return {
					status: false,
					Message: response.statusText,
					data: null
				}
			}
		} catch (errors) {
			
			if(errors.response != null && errors.response.data.code == 401){
				var res = {
					status: false,
					Message: "incorrect Username or Password",
					data: null
				};
				console.log(res)

				return res;
			}
			else{
				return {
					status: false,
					Message: errors.response.data.message,
					data: null
				}
			}
		}
	}
}
