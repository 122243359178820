import axios from "axios";
import config from "../config/index";

export default class CreditService {
	async getCredit(unit) {
		try {
			const token = JSON.parse(localStorage.getItem("mosavverData")).accessToken;
			const usrId = JSON.parse(localStorage.getItem("mosavverData")).user.id;
			const response = await axios.get(`${config.apiUrlOn}/credit/api/credit/getmycredit/` + usrId +
			"/" +
			unit,
				{ headers: { Authorization: `Bearer ${token}` } });
			if (response.status == 200) {
				return {
					status: true,
					Message: "Success",
					data: response.data
				}
			}
			else {
				return {
					status: false,
					Message: response.statusText,
					data: null
				}
			}
		} catch (errors) {
			console.log(errors)
			return {
				status: false,
				Message: errors.response.data.message,
				data: null
			}
		}
	}

	async getCostUnits() {
		try {
			const token = JSON.parse(localStorage.getItem("mosavverData")).accessToken;
			const pkgName = "free";
			const response = await axios.get(`${config.apiUrlOn}/credit/api/packages/getpackages/` + pkgName,
				{ headers: { Authorization: `Bearer ${token}` } });
			
			if (response.status === 200) {
				return {
					status: true,
					Message: "Success",
					data: response.data
				}
			}
			else {
				return {
					status: false,
					Message: response.statusText,
					data: null
				}
			}
		} catch (errors) {
			console.log(errors)
			return {
				status: false,
				Message: errors.response,
				data: null
			}
		}
	}

	async consume(amountCents, serviceName, unit) {
		try {
			const token = JSON.parse(localStorage.getItem("mosavverData")).accessToken;
			const usrId = JSON.parse(localStorage.getItem("mosavverData")).user.id;
			const response = await axios.post(`${config.apiUrlOn}/credit/api/credit/consume`, 
			{
				"userid": usrId.toString(),
				"description": "Consume Credit for " + serviceName + " " + amountCents.toString() + " CENTS",
				"payedOut": amountCents.toString(),
				unit: unit,
			},
				{ headers: { Authorization: `Bearer ${token}` } });
				console.log(response);
			if (response.status === 200) {
				return {
					status: true,
					Message: "Success",
					data: response.data
				}
			}
			else {
				return {
					status: false,
					Message: response.statusText,
					data: null
				}
			}
		} catch (errors) {
			console.log(errors)
			return {
				status: false,
				Message: errors.response.data.message,
				data: null
			}
		}
	}
}
