import React, { Suspense, lazy, useState } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
const SidebarItem = lazy(() => import('../../ImageEditor/SidebarItem'));

const Sidebar = ({ t, i18n, selected }) => {
    const DEFAULT_OPTIONS = [
        {
          name: t("Histogram_Eq"),
          property: "histogram"
        },
        {
          name: t("Reduce_Image_Size"),
          property: "reduceSize"
        },
        {
          name: t("Reduce_Mp3_Size"),
          property: "reduceVideoSize"
        }
        ,
        // {
        //   name: t("Enlarge_Image_AI"),
        //   property: "enahanceimageai"
        // },
        // {
        //   name: t("Editor_Colorize_AI"),
        //   property: "editorcolorizeai"
        // }
      ]
      const navigate = useNavigate();

      function advcedEdtSidbrNav(index) {
        if (index === 4) {
          if (i18n.language === "en")
            navigate("/Editor-Colorize-AI")
          else
            navigate("/" + i18n.language + "/Editor-Colorize-AI")
        }
        else if (index === 3) {
          if (i18n.language === "en")
            navigate("/Editor-Enlarge-AI")
          else
            navigate("/" + i18n.language + "/Editor-Enlarge-AI")
        }
        else if (index === 2) {
          if (i18n.language === "en")
            navigate("/editor-Compress-Video")
          else
            navigate("/" + i18n.language + "/editor-Compress-Video")
        }
        else if (index === 1) {
          if (i18n.language === "en")
            navigate("/editor-Compress")
          else
            navigate("/" + i18n.language + "/editor-Compress")
    
        } else if (index === 0) {
          if (i18n.language === "en")
            navigate("/editor-Histogram-eq")
          else
            navigate("/" + i18n.language + "/editor-Histogram-eq")
        }
      }
      const [options, setOptions] = useState(DEFAULT_OPTIONS);
      const [selectedOptionIndex, setSelectedOptionIndex] = useState(selected);
      function confirmPage(index) {
        setSelectedOptionIndex(index);
        advcedEdtSidbrNav(index)
      }
    return (
        <>
        <div className="sidebar">
              {options.map((option, index) => {
                return (
                  <Suspense fallback={<div>isLoading...</div>}>
                    <SidebarItem
                      key={index}
                      name={option.name}
                      property={option.property}
                      active={index === selectedOptionIndex}
                      handleClick={() => { confirmPage(index); }}
                    />
                  </Suspense>
                )
              })}
            </div>
        </>
    )
}

export default Sidebar;