
import { React, useEffect, useState } from "react";
import * as crptobox from './cryptobox'
import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import config from "../config/index";
import { QRCodeCanvas } from "qrcode.react";
import * as Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import axios from "axios";
// project imports
//import MainCard from 'ui-component/cards/MainCard';

const IFrameWrapper = styled('iframe')(({ theme }) => ({
  height: 'calc(100vh - 210px)',
  border: '1px solid',
  borderColor: theme.palette.primary.light
}));
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time)
  )
}
function PayCryptoGourl({ getStyleClass, t, i18n }) {
  const urlParams = new URLSearchParams(window.location.search);
  const amount = urlParams.get('amount');
  const orderId = urlParams.get('orderId');
  const orderDesc = urlParams.get('orderDesc');
  const payAddress = urlParams.get('payAddress');
  const expirationDate = urlParams.get('expirationDate');

  const [errTxt, setErrTxt] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(t("Are_you_sure_you"));
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [title, setTitle] = useState(t("Confirm"));

  const [payStat, setPayStat] = useState(t("waiting"));
  const [stlColor, setStlColor] = useState("yellow");

  const token = JSON.parse(localStorage.getItem("mosavverData")).accessToken;
  const usrId = JSON.parse(localStorage.getItem("mosavverData")).user.id;
  // const qrCodeEncoder = (e) => {
  //   setUrl(e);
  // };
  useEffect(() => {
    if (isLoading) {
      setInterval(async () => {
        checkPayStat();
      }, 5000);
    }
    async function checkPayStat() {
      setIsLoading(true);
      const response = await axios.get(`${config.apiUrlOn}/credit/api/transaction/gettransstatus/{"userid":"` + usrId + `", "payaddress":"`+ payAddress + `"}`
        , { headers: { Authorization: `Bearer ${token}` } });
      console.log(response.data)
      if (response.data.status == "success") {
        if(response.data.message == "Transaction is already finished"){
          setPayStat(response.data.message);
          setIsLoading(false);
        }
        if (response.data.data.status == "waiting"
          || response.data.data.status == "confirming" || response.data.data.status == "sending") {
          setPayStat(t(response.data.data.status));
        }
        else if (response.data.data.status == "confirmed") {
          setPayStat(t(response.data.data.status));
          setIsLoading(false);
        }
        else if (response.data.data.status == "partially_paid") {
          setPayStat(t(response.data.data.status));
          setIsLoading(false);
        }
        else if (response.data.data.status == "finished") {
          setPayStat(t(response.data.data.status));
          setIsLoading(false);
        }
        else if (response.data.data.status == "failed") {
          setPayStat(t(response.data.data.status));
          setIsLoading(false);
        }
        else if (response.data.data.status == "refunded") {
          setPayStat(t(response.data.data.status));
          setIsLoading(false);
        }
        else if (response.data.data.status == "expired") {
          setPayStat(t(response.data.data.status));
          setIsLoading(false);
        }
      }
      else{
        setIsLoading(false);
        setPayStat(t(response.data.message));
      }

      // if(payId != ""){
      //alert(payId)
      //const response5 = await axios.get(`${config.apiUrlNPOn}/v1/payment/`+payId, { headers: { "x-api-key": apiKey } });
      //setPayStat(response5.data.payment_status)
      //  }
    }
    checkPayStat();
  }, []);

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={payAddress}
      size={300}
      bgColor={"#00ff00"}
      level={"H"}
    />
  );

  function confirmed() {
    handleClose();

    if (message.includes(t("Activation_Email_has_been"))) {

      //   if (i18n.language === "en")
      //     navigate("/Auth/Login")
      //   else {
      //     navigate("/" + i18n.language + "/Auth/Login")
      //   }
    }

    useEffect(()=>{
      if (payStat == "waiting" || payStat == "confirming" || payStat == "sending"){
        setStlColor("yellow");
      }
      else if (payStat == "failed"){
        setStlColor("red");
      }
      else if (payStat == "expired"){
        setStlColor("blue");
      }
      else if (payStat == "confirmed"){
        setStlColor("green");
      }
      else{
        setStlColor("yellow");
      }
    },[])

  }
  return (
    <>
      <div className={"container " + getStyleClass()}>
        <div className="py-5 text-center">
          <h2>{t("Payment")}</h2>
          <p className="lead text-center">{t("Please_send_the_exact")}</p>
          <h3><label>{amount} BTC</label></h3>

        </div>
        <div className="row">
          <div className="py-1 text-center">
        {isLoading ? <div  style={{ position: "relative", color:"#1C39BB" }}>
          {/* <Loader.TailSpin />  */}
          {t("Awaiting_payment_from_you")}</div> : <></>}

            <div hidden={!payAddress}>{qrcode}</div>
            <label>{payAddress}</label>

          </div>
        </div>
        <div className="row">
        <div className="py-1 text-center">
        <label style={{color: {stlColor}}}>{payStat}</label>
</div>
          </div>
        <Modal className={getStyleClass()}
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              {message}
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Cancel")}
            </Button>
            <Button variant="primary" onClick={confirmed} >{t("OK")}</Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* <label>status:</label>
      {stat}
      <label>min:</label>
      {statMin}
      <label> 8 USD in BTC:</label>
      {toBtc}
      <label> purchase_id:</label>
      {resPay}
      <label> created_at:</label>
      {crAt}
      <label> expiration_estimate_date:</label>
      {exp}
      <label> payment_id:</label>
      {payId}
      <label> payment_status:</label>
      {payStat}
      <label> pay_address:</label>
      {payAd} */}


      {/* <button onClick={payment}>
        Pay
      </button> */}
    </>
  )
}

export default PayCryptoGourl;