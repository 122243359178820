import React, { Suspense } from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import * as serviceWorker from './serviceWorker';
import AuthService from "./Services/auth.service";
import CreditService from "./Services/credit.service"
import * as ArticlesService from "./Services/articles";
import NotificationsService from "./Services/notification";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'G-CP41K4H13N');
};
root.render(
  <>
  {/* Global site tag (gtag.js) - Google Analytics */}
  <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-CP41K4H13N"
    />
    <script>{injectGA()}</script>
    <BrowserRouter>
    <Suspense fallback="...loading">
      <App 
          authService={new AuthService()}
          creditService = {new CreditService()}
          articlesService={ArticlesService}
          notyService={new NotificationsService()}/>
    </Suspense>
    </BrowserRouter>
  </>,
);
serviceWorker.unregister();