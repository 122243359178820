
import axios from "axios";

export default 
{
  enhanceServer:axios.create({
      baseURL: "http://localhost:8000",
      headers: {
        "accept": "application/json"
      }
    }),
  faceServer:axios.create({
      baseURL: "http://31.7.67.180",
      headers: {
        "accept": "application/json"
      }
    }),
    plateServer:axios.create({
      baseURL: "http://localhost:8002",
      headers: {
        "accept": "application/json"
      }
    })
};
