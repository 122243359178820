import Badge from 'react-bootstrap/Badge';

function SiteBadge({getStyleClass={getStyleClass}, t, i18n, txt}){

    return (
        <>
        <Badge as="textarea" pill={true} style={{width:"100%", height:"25px", fontSize:"13px", alignItems:"center"}} className={getStyleClass()} bg="dark" >
            {txt}
        </Badge>
        </>
    )
}

export default SiteBadge;