import http from "../http-common";

class UploadFilesService {
  uploadAncient(file, name, onUploadProgress) {
    
    let formData = new FormData();

    formData.append("uploaded_file", file);

    return http.enhanceServer.post("/enhancer/upload-file/?name=" + name, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
            },
    });
  }

  getFilesAncient(name) {
    return http.enhanceServer.get("/enhancer/image_dir/", { params: { image_dir: name }, responseType: "arraybuffer" });
  }

  //Face
  uploadFace(file, name, onUploadProgress) {   
    let formData = new FormData();
    formData.append("uploaded_file", file);
    return http.faceServer.post("/api/faceEnh/face/upload-file/?name=" + name, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
            },
    });
  }

  getFilesFace(name) {
    return http.faceServer.get("/api/faceEnh/face/image_dir/", { params: { image_dir: name }, responseType: "arraybuffer" });
  }

  //License Plate
  uploadPlate(file, name, onUploadProgress) {   
    let formData = new FormData();
    formData.append("uploaded_file", file);
    return http.plateServer.post("/license-plate/upload-file/?name=" + name, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
            },
    });
  }

  getFilesPlate(name) {
    return http.plateServer.get("/license-plate/image_dir/", { params: { image_dir: name }, responseType: "arraybuffer" });
  }

  //StyleGAN
  uploadFaceBeauty(file, name, onUploadProgress) {   
    let formData = new FormData();
    formData.append("uploaded_file", file);
    return http.plateServer.post("/face-beauty/upload-file/?name=" + name, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
            },
    });
  }

  getFilesFaceBeauty(name) {
    return http.plateServer.get("/face-beauty/image_dir/", { params: { image_dir: name }, responseType: "arraybuffer" });
  }
}

export default new UploadFilesService();
