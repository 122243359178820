import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import * as Loader from 'react-loader-spinner';
import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
  from 'mdb-react-ui-kit';
import { useEffect } from 'react';

function App({ getStyleClass, isLoggedin, setIsLoggedin, loginUser, registerUser, t, i18n }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedin) {
      if (i18n.language === "en")
        location.href = "/panel/account/user-info"
      else
        location.href = "/panel/" + i18n.language + "/account/user-info"
      return;
    }

    // const urlParams = new URLSearchParams(window.location.search);
    //     const myParamSignup = urlParams.get('signup');
    //     if(myParamSignup === "true"){
    //       handleJustifyClick('tab2');
    //     }
  })
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [title, setTitle] = useState(t("Confirm"));
  const [message, setMessage] = useState(t("Are_you_sure_you"));
  const [justifyActive, setJustifyActive] = useState('tab1');
  const [errTxt, setErrTxt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const handleJustifyClick = (value) => {

    if (value === justifyActive) {
      return;
    }
    // if(value == "tab1"){
    //   if(location.href.includes("signup"))
    //   location.href="/auth/login"
    // }
    setJustifyActive(value);
  };
  const signIn = async () => {
    const data = {
      email: document.getElementById("email_signin").value,
      password: document.getElementById("pass_signin").value,
      errors: ""
    };
    setIsLoading(true);
    var res = await loginUser(data);
    setIsLoading(false);
    console.log(res)
    if (res.status == false) {
      if (res.Message == "User is not activated") {
        setMessage(t("User_is_not_activated"));
        setTitle(t("Dear_User"));
        handleShow();

        setErrTxt(t("User_is_not_activated"))
      }
      else if (res.Message == "User not found") {
        setMessage(t("Incorrect_email_or_password"));
        setTitle(t("Dear_User"));
        handleShow();
        setErrTxt(t("Incorrect_email_or_password"))

      }
      else if (res.Message == "User is deleted") {
        setMessage(t("User_is_deleted"));
        setTitle(t("Dear_User"));
        handleShow();
        setErrTxt(t("User_is_deleted"))

      }
      else if (res.Message == "incorrect Username or Password") {
        setMessage(t("Incorrect_email_or_password"));
        setTitle(t("Dear_User"));
        handleShow();
        setErrTxt(t("Incorrect_email_or_password"))

      }
      else {
        setMessage(t("Incorrect_email_or_password"));
        setTitle(t("Dear_User"));
        handleShow();
        setErrTxt(t("Incorrect_email_or_password"))
      }
    }
    else {
      localStorage.setItem("mosavverData", JSON.stringify(res.data));
      setIsLoggedin(true);
      setMessage(t("You_logged_In_successfully"));
      setTitle(t("Dear_User"));
      handleShow();
      setErrTxt(t("You_logged_In_successfully"))

    }

  }

  const signUp = async () => {
    if(document.getElementById("fullname_signup").value == "null"
      ||document.getElementById("email_signup").value == "null"
      ||document.getElementById("pass_signup").value == "null"
      ||document.getElementById("repass_signup").value == "null"
      ||document.getElementById("pass_signup").value != document.getElementById("repass_signup").value){

        return;
    }
    const data = {
      name: document.getElementById("fullname_signup").value,
      email: document.getElementById("email_signup").value,
      password: document.getElementById("pass_signup").value,
      errors: "",
      lang: i18n.language,
    };
    setIsLoading(true);
    var res = await registerUser(data);
    setIsLoading(false);

    if (res.status) {
      setMessage(t("Activation_Email_has_been"));
      setTitle(t("Dear_User"));
      handleShow();
      setErrTxt(t("Activation_Email_has_been"))
    }
    else {
      if (res.Message == "This email is already registered") {
        setMessage(t("You_are_already_registered"));
        setTitle(t("Dear_User"));
        handleShow();
      setErrTxt(t("You_are_already_registered"))

      }
    }
  }
  function confirmed() {
    handleClose();

    if (message.includes(t("Activation_Email_has_been"))) {
      if (i18n.language === "en")
        navigate("/Auth/Login")
      else {
        navigate("/" + i18n.language + "/Auth/Login")
      }
    }
    else if (message.includes(t("You_logged_In_successfully"))) {
      if (i18n.language === "en")
        navigate("/")
      else {
        navigate("/" + i18n.language)
      }
    }
  }

  return (
    <>
      <div className={getStyleClass()}>
      {isLoading ? <div style={{fontFamily:"BYekan",color:"white", marginRight:"50%", marginLeft:"50%", marginTop:"10%", zIndex:"24", textAlign:"center", position:"absolute"}}><Loader.TailSpin  /> {t("loading")}</div> : <></>}
        <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
          <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                {t("Login")}
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                {t("Register")}
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
          <MDBTabsContent>
            <MDBTabsPane show={justifyActive === 'tab1'}>

            {/* <form className="needs-validation" novalidate> */}
                            <div >
                            <div className="mb-3">
                                <input type="email" className="form-control" id="email_signin" placeholder={t('Email_address')} required/>
                                <div className="invalid-feedback">
                                {t("Please_enter_a_valid")}
                                </div>
                            </div>

                            <div className="mb-3">
                                <input type="password" className="form-control" id="pass_signin" placeholder={t("Password")} required />
                                <div className="invalid-feedback">
                                {t("Please_enter_your_password.")}
                                </div>
                            </div>
                            </div>

                            <hr className="mb-4" />
                            <button className="btn btn-primary btn-lg btn-block" onClick={signIn} >{t('Sign_in')}</button>
              {/* </form> */}
              {/* <MDBInput wrapperClass='mb-4' placeholder={t('Email_address')} id='email_signin' type='email' />
              <MDBInput wrapperClass='mb-4' placeholder={t('Password')} id='pass_signin' type='password' /> */}
              {/* <div className="d-flex justify-content-between mx-4 mb-4">
                <a href="!#">{t('Forgot_password')}</a>
              </div> */}
              {/* <MDBBtn className="mb-4 w-100" onClick={signIn}>{t('Sign_in')}</MDBBtn> */}
            </MDBTabsPane>
            <MDBTabsPane show={justifyActive === 'tab2'}>
            {/* <form className="needs-validation" novalidate> */}
                            <div >
                            <div className="mb-3">
                                <input type="text" className="form-control" id="fullname_signup" placeholder={t('Full_Name')} required/>
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control" id="email_signup" placeholder={t('Email')} required/>
                                <div className="invalid-feedback">
                                {t("Please_enter_a_valid")}
                                </div>
                            </div>

                            <div className="mb-3">
                                <input type="password" className="form-control" id="pass_signup" placeholder={t("Password")} required />
                                <div className="invalid-feedback">
                                {t("Please_enter_your_password.")}
                                </div>
                            </div>
                            <div className="mb-3">
                                <input type="password" className="form-control" id="repass_signup" placeholder={t("Repeat_Password")} required />
                                <div className="invalid-feedback">
                                {t("Please_Re_enter_your")}
                                </div>
                            </div>

                            </div>

                            <hr className="mb-4" />
                            <button className="btn btn-primary btn-lg btn-block" onClick={signUp} type="submit">{t('Sign_up')}</button>
              {/* </form> */}
              <div hidden={errTxt===""?true:false}>
              <hr className="mb-4" />

              <div class="alert alert-danger" role="alert">
                {errTxt}
              </div>
              </div>
              
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBContainer>
        <Modal className={getStyleClass()}
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header >
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              {message}
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Cancel")}
            </Button>
            <Button variant="primary" onClick={confirmed} >{t("OK")}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default App;