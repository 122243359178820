import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from './en/en.json'
import translationsHE from './fa/fa.json'
import translationsAR from './ar/ar.json'
import translationsHI from './hi/hi.json'
import translationsRU from './ru/ru.json'
import translationsTR from './tr/tr.json'
import translationsZH from './zh/zh.json'


const resources = {
  en: {
    translation: translationsEN
  },
  fa: {
    translation: translationsHE
  }
  ,
  ar: {
    translation: translationsAR
  }
  ,
  hi: {
    translation: translationsHI
  }
  ,
  ru: {
    translation: translationsRU
  }
  ,
  tr: {
    translation: translationsTR
  }
  ,
  zh: {
    translation: translationsZH
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en', // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  
  export default i18n;