import { React, lazy, Suspense, useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import loggo from "./Pictures/logoo.jpg"
import LoadingScreen from 'react-loading-screen';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next'
import './i18n/i18n.js'
import { Routes, Route, Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import Img from "./Components/Img";
import banner from "../src/Pictures/banner.png";

import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
import PayCryptoGourl from "./Payment/payCryptoGourl";
import SiteBadge from "./SiteBadge";
import Checkout from "./Payment/checkout/Checkout";
import Sidebar  from "./Components/SideBarEditorAd/SideBar"
import Face from "./Face";
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Contact = lazy(() => import('./Contact'));
const Service = lazy(() => import('./Service'));

const Editor_iframe = lazy(() => import('./Editor_iframe'));
const Editor = lazy(() => import('./Editor'));
const Editor_HE = lazy(() => import('./Editor_HE'));
const Editor_Compress = lazy(() => import('./Editor_Compress'));
const Editor_Compress_Audio = lazy(() => import('./Editor_Compress_Audio'));
const Editor_Enlarge_AI = lazy(() => import('./Editor_Enlarge_AI'));
const Editor_Colorize_AI = lazy(() => import('./Editor_Colorize_AI'));
const Editor_Face_Beauty_AI = lazy(() => import('./Editor_Face_Beauty_AI'));
const Posts_Main = lazy(() => import('./Posts_Main'));
const Post = lazy(() => import('./Post'));
const Navbarr = lazy(() => import('./Navbar'));
const TRACKING_ID = "G-CP41K4H13N"; // OUR_TRACKING_ID

function App({ authService, creditService, articlesService, notyService }) {
  const dollarPrice = process.env.DOLLARPRICE;
  const [isLoggedin, setIsLoggedin] = useState(false);

  const [navHide, setNavHide] = useState(false);

  const [active, setActive] = useState('default');
  const [images, setImages] = useState([]);

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();

  function _getCookie(NameOfCookie) {
    if (document.cookie.length > 0) {
      var begin = document.cookie.indexOf(NameOfCookie + "=");
      if (begin != -1) {
        begin += NameOfCookie.length + 1;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1)
          end = document.cookie.length;
        return document.cookie.substring(begin, end);
      }
    }
    return '';
  }
  function _setCookie(NameOfCookie, value, expire_days) {
    if (expire_days == undefined)
      expire_days = 180;
    var ExpireDate = new Date();
    ExpireDate.setTime(ExpireDate.getTime() + (expire_days * 24 * 3600 * 1000));
    document.cookie = NameOfCookie + "=" + value +
      ((expire_days == null) ? "" : "; expires=" + ExpireDate.toGMTString());
  }
  function setCookie(name, value) {
    var cookie = _getCookie('config');
    if (cookie == '')
      cookie = {};
    else
      cookie = JSON.parse(cookie);

    cookie[name] = value;
    var cookie = JSON.stringify(cookie);

    _setCookie('config', cookie);
  }
  useEffect(() => {
    if (location.pathname.split('/')[1].length == 2) {
      const lang = location.pathname.split('/')[1];
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;
      //save cookie
      setCookie('language', lang);
    }

    else {
      i18n.changeLanguage("en")
      document.documentElement.lang = "en";
      //save cookie
      setCookie('language', "en");
    }

    if (localStorage.getItem("mosavverData") != null) {
      setIsLoggedin(true);
    }
  }, [i18n])
  var isNumeric = function (num) {
    return (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);
  }
  var firstPath = "";
  if (location.pathname.split('/')[1].length == 2 && !isNumeric(firstPath)) {
    firstPath = location.pathname.split('/')[1];
  }
  else {
    firstPath = ""
  }

  const pathObj = {}

  pathObj.path_home = "/" + firstPath;
  pathObj.path_about = firstPath != "" ? "/" + firstPath + "/about" : "/about";
  pathObj.path_service = firstPath != "" ? "/" + firstPath + "/service" : "/service";
  pathObj.path_contact = firstPath != "" ? "/" + firstPath + "/contact" : "/contact";
  pathObj.path_editor = firstPath != "" ? "/" + firstPath + "/editor" : "/editor";
  pathObj.path_editor_he = firstPath != "" ? "/" + firstPath + "/editor-Histogram-eq" : "/editor-Histogram-eq";
  pathObj.path_editor_comprss = firstPath != "" ? "/" + firstPath + "/editor-Compress" : "/editor-Compress";
  pathObj.path_editor_cmprss_vdeo = firstPath != "" ? "/" + firstPath + "/editor-Compress-Video" : "/editor-Compress-Video";
  pathObj.path_editor_enahance_ai = firstPath != "" ? "/" + firstPath + "/Editor-Enlarge-AI" : "/Editor-Enlarge-AI";
  pathObj.path_editor_colorize_ai = firstPath != "" ? "/" + firstPath + "/Editor-Colorize-AI" : "/Editor-Colorize-AI";
  pathObj.path_posts_main = firstPath != "" ? "/" + firstPath + "/Posts-Main" : "/Posts-Main";
  pathObj.path_blog = firstPath != "" ? "/" + firstPath + "/Blog" : "/Blog";
  pathObj.path_post = firstPath != "" ? "/" + firstPath + "/Post/:slug" : "/Post/:slug";
  pathObj.path_login_signup = firstPath != "" ? "/" + firstPath + "/Auth/Login" : "/Auth/Login";
  pathObj.path_editor_iframe = firstPath != "" ? "/" + firstPath + "/Editor-IF" : "/Editor-IF";
  pathObj.path_logout = firstPath != "" ? "/" + firstPath + "/Auth/Logout" : "/Auth/Logout";
  pathObj.path_pay_crypto = firstPath != "" ? "/" + firstPath + "/Payment/PayCrypto" : "/Payment/PayCrypto";
  pathObj.path_payment_checkout = firstPath != "" ? "/" + firstPath + "/Payment/Checkout" : "/Payment/Checkout";
  pathObj.path_editor_beauty = firstPath != "" ? "/" + firstPath + "/Editor-Beauty-AI" : "/Editor-Beauty-AI";

  pathObj.path_face = firstPath != "" ? "/" + firstPath + "/Face-Enhance" : "/Face-Enhance";

  function getStyleClass() {
    var className = "nonPersianStyle";
    if (i18n.language === "ar" || i18n.language === "fa") {
      className = "persianStyle";
    }
    return className
  }
  const checkIsPaiedService = (imgPixls = 0, vidPixls = 0) => {
    const hd = 1280 * 720;
    const res = {};
    if (imgPixls > 0 && vidPixls > 0) {
      res.success = "false";
      res.message = "Image and Video cannot be greater than 0 at the same time.";
    }
    if (imgPixls > 0) {
      if (imgPixls <= hd) {
        res.success = "true";
        res.data = { isPaied: "false" };
      } else {
        res.success = "true";
        res.data = { isPaied: "true" };
      }
    }
    if (vidPixls > 0) {
      res.success = "true";
      res.data = { isPaied: "true" };
    }
    return res;
  }
  const checkCredit = async () => {
    const res = {};
    const unit = i18n.language === "fa" ? "toman" : "usd"
    if (isLoggedin) {
      const resC = await creditService.getCredit(unit);
      if(resC.status){
        res.success = "true";
        res.data = resC.data;
      }
      return res;
    } else {
      res.success = "false";
      res.message = "Not loggedin";
    }
    return res;
  }
  const checkCost = async (imgVal, vidVal) => {
    const res = {};
    const unit = i18n.language === "fa" ? "toman" : "usd"
    if (isLoggedin) {
      const resC = await creditService.getCostUnits();
      console.log(resC)
      if(resC.status && resC.data.status === "success"){
        res.success = "true";
        res.data = unit === "toman" ? resC.data.data.pricing * dollarPrice : resC.data.data.pricing;
      } else {
        res.success = "false";
        res.message = "Error in server";
      }
      return res;
    } else {
      res.success = "false";
      res.message = "Not loggedin";
    }
    return res;
  }
  const consumeCredit = async (amountCents, desc) => {
    const res = {};
    const unit = i18n.language === "fa" ? "toman" : "usd"
    if (isLoggedin) {
      const resC = await creditService.consume(amountCents, desc, unit);
      console.log(resC)
      if(resC.status && resC.data.status === "success"){
        res.success = "true";
        res.data = resC.data;
      } else {
        res.success = "false";
        res.message = "Error in server";
      }
      return res;
    } else {
      res.success = "false";
      res.message = "Not loggedin";
    }
    return res;
  }
  function validateURL(url) {
    const parsed = new URL(url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }
  return (
    <>
      <HelmetProvider>
        <Suspense fallback={<LoadingScreen
          loading={true}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
          logoSrc={loggo}
          children=""
        ></LoadingScreen>}>
          {/* <SiteBadge getStyleClass={getStyleClass} t={t} i18n={i18n} txt={t("Dear_people_of_Turkey")} ></SiteBadge> */}
          <div hidden={navHide}>
            <Navbarr getStyleClass={getStyleClass} isLoggedin={isLoggedin} setIsLoggedin={setIsLoggedin} pathObj={[pathObj]} active={active} setActive={setActive} t={t} i18n={i18n} />
          </div>
      <img onClick={()=>document.location="https://asrpeaz.ir/gift"} hidden={firstPath !== "fa" ? true : false} id="ads" width="200px" style={{position:"fixed", zIndex:"100"}} alt="banner" src={banner}></img>  

          <Routes>
            <Route path={pathObj.path_home} element={<Home getStyleClass={getStyleClass} path_contact={pathObj.path_contact} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={"/" + firstPath + "/"} element={<Home getStyleClass={getStyleClass} path_contact={pathObj.path_contact} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_about} element={<About getStyleClass={getStyleClass} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_service} element={<Service getStyleClass={getStyleClass} path_contact={pathObj.path_contact} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_contact} element={<Contact getStyleClass={getStyleClass} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_editor} element={<Editor getStyleClass={getStyleClass} setNavHide={setNavHide} images={images} setImages={setImages} Img={Img} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_editor_he} element={<Editor_HE Sidebar={Sidebar} checkCred={checkCredit} checkCost={checkCost} consumeCredit={consumeCredit} isLoggedin={isLoggedin} checkIsPaiedService={checkIsPaiedService} getStyleClass={getStyleClass} images={images} setImages={setImages} Img={Img} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_editor_comprss} element={<Editor_Compress Sidebar={Sidebar} checkCred={checkCredit} checkCost={checkCost} consumeCredit={consumeCredit} isLoggedin={isLoggedin} checkIsPaiedService={checkIsPaiedService} getStyleClass={getStyleClass} images={images} setImages={setImages} Img={Img} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_editor_cmprss_vdeo} element={<Editor_Compress_Audio Sidebar={Sidebar} checkCred={checkCredit} checkCost={checkCost} consumeCredit={consumeCredit} isLoggedin={isLoggedin} checkIsPaiedService={checkIsPaiedService} getStyleClass={getStyleClass} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_editor_enahance_ai} element={<Editor_Enlarge_AI Sidebar={Sidebar} checkCred={checkCredit} checkCost={checkCost} consumeCredit={consumeCredit} isLoggedin={isLoggedin} checkIsPaiedService={checkIsPaiedService} getStyleClass={getStyleClass} images={images} setImages={setImages} Img={Img} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_editor_colorize_ai} element={<Editor_Colorize_AI Sidebar={Sidebar} checkCred={checkCredit} checkCost={checkCost} consumeCredit={consumeCredit} isLoggedin={isLoggedin} checkIsPaiedService={checkIsPaiedService} getStyleClass={getStyleClass} images={images} setImages={setImages} Img={Img} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_editor_beauty} element={<Editor_Face_Beauty_AI Sidebar={Sidebar} checkCred={checkCredit} checkCost={checkCost} consumeCredit={consumeCredit} isLoggedin={isLoggedin} checkIsPaiedService={checkIsPaiedService} getStyleClass={getStyleClass} images={images} setImages={setImages} Img={Img} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_login_signup} element={<Login getStyleClass={getStyleClass} isLoggedin={isLoggedin} setIsLoggedin={setIsLoggedin} loginUser={authService.loginUser} registerUser={authService.registerUser} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_editor_iframe} element={<Editor_iframe getStyleClass={getStyleClass} setNavHide={setNavHide} isLoggedin={isLoggedin} setIsLoggedin={setIsLoggedin} loginUser={authService.loginUser} registerUser={authService.registerUser} setActive={setActive} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_logout} element={<Logout getStyleClass={getStyleClass} isLoggedin={isLoggedin} setIsLoggedin={setIsLoggedin} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_payment_checkout} element={<Checkout loginUser={authService.loginUser} registerUser={authService.registerUser} getStyleClass={getStyleClass} isLoggedin={isLoggedin} setIsLoggedin={setIsLoggedin} t={t} i18n={i18n} />} />
            <Route path={pathObj.path_pay_crypto} element={<PayCryptoGourl loginUser={authService.loginUser} registerUser={authService.registerUser} getStyleClass={getStyleClass} isLoggedin={isLoggedin} setIsLoggedin={setIsLoggedin} t={t} i18n={i18n} />} />
         
            <Route path={pathObj.path_face} element={<Face setActive={setActive} loginUser={authService.loginUser} registerUser={authService.registerUser} getStyleClass={getStyleClass} isLoggedin={isLoggedin} setIsLoggedin={setIsLoggedin} t={t} i18n={i18n} />} />
          </Routes>
          <footer className={"my-5 pt-5 text-muted text-center text-small " + getStyleClass()}>
            <p className="mb-1">&copy; {t("2017_2019_Company_Name")}</p>
            <ul className="list-inline">
              <li className="list-inline-item"><a href="#">{t("Privacy")} </a></li>
              <li className="list-inline-item"><a href="#">{t("Terms")} </a></li>
              <li className="list-inline-item"><a href="#">{t("Support")} </a></li>
            </ul>
          </footer>
        </Suspense>
      </HelmetProvider>
    </>
  );
}

export default App;
