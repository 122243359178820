import Axios from "axios";
import config from "../config";
import {useState} from 'react'
const { validateAll } = window;
var slug = ""
// var cnt = {next_page_url:"google.com", prev_page_url:"yahoo.com", data:[
// 		{id:1, title: "background-size",
// 		content: "The background-size property in CSS is one of the most useful — and most complex — of the background properties. There are many variations and different syntaxes you can use for this property, all of which have different use cases. Here’s a basic example:",
// 		slug:"First-slug", created_at:"2023-01-05", user:{name:"M.Nasiri"},
// 		category:{name:"image enhancemet"}, imageUrl:"http://localhost:3000/static/media/img3.c42a0d9fe015e6bd2f05.png"},
// 		{id:2, title: "requiredt",
// 		content: "requiredc",
// 		slug:"Second-slug", created_at:"2023-01-06", user:{name:"M.Nasiri"},
// 		category:{name:"cat2"}, imageUrl:"http://localhost:3000/static/media/img3.c42a0d9fe015e6bd2f05.png"},
// 		{id:3, title: "requiredt",
// 		content: "requiredc",
// 		slug:"Third-slug", created_at:"2023-01-05", user:{name:"M.Nasiri"},
// 		category:{name:"cat1"}, imageUrl:"http://localhost:3000/static/media/img3.c42a0d9fe015e6bd2f05.png"}]}


	export async function getArticles(url = `${config.apiUrl}/articles`) {
		const response = await Axios.get(url);
		console.log(response.data);
		return response.data;
	}

	export async function getUserArticles(token, url = `${config.apiUrl}/user/articles`) {
		const response = await Axios.get(url, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		return response.data;
	}

	export async function deleteArticle(id, token) {
		await Axios.delete(`${config.apiUrl}/articles/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		return true;
	}
	function filterBySlug(value) {
				if(value.slug === slug){
					return value;
				}

	  }
	export async function getArticle(slugg) {
		slug = slugg.replace(".html", "");
		const response = await Axios.get(`${config.apiUrl}/article/${slug}`);
		//var res = cnt.data.filter(filterBySlug);
		//console.log(slug)
		//console.log(res)

		return response;
	}

	export async function getArticleCategories() {
		const categories = JSON.parse(localStorage.getItem("categories"));
		if (categories) {
			return categories;
		}
		const response = await Axios.get(`${config.apiUrl}/categories`);
		localStorage.setItem(
			"categories",
			JSON.stringify(response.data.categories)
		);
		return response.data.categories;
	}

	export async function createArticle(data, token) {
		if (!data.image) {
			return Promise.reject([{ message: "The image is required" }]);
		}

		try {
			const rules = {
				title: "required",
				content: "required",
				category: "required"
			};
			const messages = {
				required: "The {{field}} is required"
			};
			await validateAll(data, rules, messages);

			const image = await this.uploadToCloudinary(data.image);
			const response = await Axios.post(
				`${config.apiUrl}/articles`,
				{
					title: data.title,
					content: data.content,
					category_id: data.category,
					imageUrl: image.secure_url
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
			return response.data;
		} catch (errors) {
			if (errors.response) {
				return Promise.reject(errors.response.data);
			}
			return Promise.reject(errors);
		}
	};

	export async function updateArticle(data, article, token){
		let image;
		if (data.image) {
			image = await this.uploadToCloudinary(data.image);
		}

		try {
			const rules = {
				title: "required",
				content: "required",
				category: "required"
			};
			const messages = {
				required: "The {{field}} is required"
			};
			await validateAll(data, rules, messages);
			const response = await Axios.put(
				`${config.apiUrl}/articles/${article.id}`,
				{
					title: data.title,
					content: data.content,
					category_id: data.category,
					imageUrl: image ? image.secure_url : article.imageUrl
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
			return response.data;
		} catch (errors) {
			if (errors.response) {
				return Promise.reject(errors.response.data);
			}
			return Promise.reject(errors);
		}
	};

	// export async function uploadToCloudinary(image) {
	// 	const form = new FormData();
	// 	form.append("file", image);
	// 	form.append("upload_preset", "kkznocck");
	// 	const response = await Axios.post(
	// 		"https://api.cloudinary.com/v1_1/du0haiwsz/image/upload",
	// 		form
	// 	);
	// 	return response.data;
	// }
