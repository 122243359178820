import {React} from 'react'


const Img = ({id, alt, image, className, style})=>{


    return(
        <>
            <img id ={id} alt={alt} src={image.src} className={className} style={style}/>
        </>
    )
}

export default Img