import React, { useState, Suspense, lazy } from "react";
const ShowImage = lazy(() => import('./Components/ShowImage'));
const DropBox = lazy(() => import('./Components/DropBox'));
import UploadService from "./Services/upload-files.service";
import * as Loader from 'react-loader-spinner';

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const Face = ({ getStyleClass, setActive, i18n }) => {
  setActive("Face")

  const [isLoading, setIsLoading] = useState(false);
  const [imagesOut, setImagesOut] = useState([]);
  const imgsOutArr = [];
  var name = ""
  function upload(currentFile) {
    //console.log("mosavver_out_"+currentFile.name)
    name = makeid(64)
    setIsLoading(true);

    UploadService.uploadFace(currentFile, name, (event) => {

    })
      .then((response) => {
        return UploadService.getFilesFace(name).then((acceptedFiles) => {
          const base64 = btoa(
            new Uint8Array(acceptedFiles.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          )
          setImagesOut(oldArray => [...oldArray, base64]);
          //setImagesOut(base64)
          var a = document.createElement("a"); //Create <a>
          a.href = "data:image/png;base64," + base64; //Image Base64 Goes here
          a.download = "mosavver_out_" + currentFile.name; //File name Here
          a.click(); //Downloaded file

          //location.href = 'data:application/octet-stream;base64,' + base64;
          setIsLoading(false)

        }).catch((ex) => {
          setIsLoading(false);
          alert(ex);
        });;
      })
      .catch((ex) => {
        setIsLoading(false);
        alert(ex);
      });
  }
  const [images, setImages] = useState([]);
  const onDrop = (acceptedFiles) => {
    setImages([])
    setImagesOut([])
    acceptedFiles.map((file, index) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { id: index, src: e.target.result },
        ]);
      };

      reader.readAsDataURL(file);
      //fruits.forEach(myFunction);
      upload(file);

      return file;
    });

  };

  return (
    <>
      <div className="my-5 p-0">
        <h1 className="text-center">Face</h1>
      </div>

      <div className="container d-flex align-items-center justify-content-center">
        {isLoading ? <Loader.TailSpin /> : <></>}

      </div>
      <Suspense fallback={<div>isLoading...</div>}>
        <DropBox onDrop={onDrop} isLoading={isLoading} clickName="Click to select file" dragTitleName="Drag & drop your file here" />
      </Suspense>
      <div class="row">
        <div class="col">

          <Suspense fallback={<div>isLoading...</div>}>
            <ShowImage fl="left" images={images} out="0" />
          </Suspense>
        </div>
        <div class="col">
          <Suspense fallback={<div>isLoading...</div>}>
            <ShowImage fl="right" images={imagesOut} out="1" />
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default Face;
