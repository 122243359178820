import React from 'react';

import { useEffect } from 'react';

function App({ setIsLoggedin }) {

  useEffect(()=>{
    localStorage.removeItem("mosavverData")
    setIsLoggedin(false);
    location.href="/Auth/Login";
  })
  
 

  return (
    <>
    
    </>

  );
}

export default App;